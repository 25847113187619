import { Fragment, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchepisode } from "../redux/slice/Movie";
import Footer from "../components/Footer";
import Loader from "../components/UI/Loader";
import ErrorMessage from "../components/UI/ErrorMEssage";

import {
  FaPlay,
  FaPause,
  FaVolumeUp,
  FaVolumeMute,
  FaExpand,
} from "react-icons/fa";
// import './VideoPlayer.css';

export default function Movie() {
  // get template id from url
  const { id } = useParams();
  const dispatch = useDispatch();

  const { movie, error, loading } = useSelector((state) => state.movie);

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [constrollsVisible, setControllsVisible] = useState("active");

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    videoRef.current.volume = newVolume;
    setIsMuted(newVolume === 0);
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    videoRef.current.muted = !isMuted;
  };

  const handleProgress = () => {
    const progress =
      (videoRef.current.currentTime / videoRef.current.duration) * 100;
    setProgress(progress);
  };

  const handleSeek = (e) => {
    const newTime = (e.target.value / 100) * videoRef.current.duration;
    videoRef.current.currentTime = newTime;
    setProgress(e.target.value);
  };

  const handleFullscreen = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    }
  };

  useEffect(() => {
    dispatch(fetchepisode({ templateID: id }));
  }, []);

  // useEffect(() => {
  //   let timeOut = setTimeout()
  //   if (constrollsVisible === "active"){
  //   }

  // }, [constrollsVisible])

  if (process.env.NODE_ENV === "production" && loading) {
    return <Loader message="loading you episode" />;
  }
  if (process.env.NODE_ENV === "production" && error) {
    return <ErrorMessage message={error} />;
  }
  return (
    <div className="w-full flex gap-2 flex-col pb-8 pt-4 h-full max-h-full max-w-md mx-auto overflow-hidden box-border">
      <header className="py-4 px-4 flex items-center gap-4">
        <img src="/logo2.png" className="size-10" />
        <span className="text-2xl">Zingroll Movies</span>
      </header>
      {/* video player */}
      <div className="relative h-[250px] w-full max-w-4xl mx-auto rounded-md bg-black">
        {/* < */}
        <video
          ref={videoRef}
          src={
            process.env.NODE_ENV === "development"
              ? "https://static-content-for-text.s3.amazonaws.com/chunks/video_p4rgi5bppsg89cangiex/output_3.mp4"
              : movie?.videoUrl
          }
          onTimeUpdate={handleProgress}
          onClick={togglePlayPause}
          className="w-full  cursor-pointer"
        ></video>
        {/* custom controls */}
        <div className="absolute h-full bottom-4 left-0 right-0 flex items-center justify-around px-4 py-2 bg-black bg-opacity-70 text-white">
          <button
            onClick={togglePlayPause}
            className="text-2xl absolute top-[50%] right-[50%] translate-x-1/2 -translate-y-1/2"
          >
            {isPlaying ? <FaPause /> : <FaPlay />}
          </button>
          <div className="absolute flex flex-col gap-2 bottom-0 left-0 right-0">
            <input
              type="range"
              min="0"
              max="100"
              value={progress}
              onChange={handleSeek}
              className="h-2"
            />

            {/* volume slider */}
            {/* <button onClick={toggleMute} className="text-2xl">
              {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
            </button>

            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={isMuted ? 0 : volume}
              onChange={handleVolumeChange}
              className="w-24 mx-2 h-2"
            /> */}
            <button onClick={handleFullscreen} className="self-end text-2xl">
              <FaExpand />
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 px-4 mb-auto">
        <p className="mt-4 mb-2 text-lg">{movie?.name}</p>
        <p className="text-neutral-400 text-sm ">{movie?.description}</p>
      </div>
      <Footer />
    </div>
  );
}
