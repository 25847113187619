// ErrorMessage.jsx
import { AlertCircle } from "lucide-react";

export default function ErrorMessage({ message = "Something went wrong.", onRetry }) {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-white space-y-4">
      <AlertCircle className="text-red-500" size={32} />
      <span className="text-gray-300 text-xs text-center mx-4">{message}</span>
      {onRetry && (
        <button
          onClick={onRetry}
          className="px-4 py-2  font-medium  bg-red-500 rounded-md hover:bg-red-600 transition"
        >
          Retry
        </button>
      )}
    </div>
  );
}
