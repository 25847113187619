import { Fragment } from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Movie from "./pages/Movie";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Invite from "./pages/Invite";

function App() {
  return (
    <Fragment>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Movie />} path="/share/:id" />
        <Route element={<Terms />} path="/terms" />
        <Route element={<Privacy />} path="/privacy" />
        <Route element={<Invite />} path="/invite/:id" />
      </Routes>
    </Fragment>
  );
}

export default App;
