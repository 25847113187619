import { useSelector, useDispatch } from "react-redux";
import { fetchUser } from "../redux/slice/Invite";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Loader from "../components/UI/Loader";
import ErrorMessage from "../components/UI/ErrorMEssage";
import Footer from "../components/Footer";

export default function Invite() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.invite);

  useEffect(() => {
    dispatch(fetchUser({ id }));
  }, [dispatch, id]);

  if (loading)
    return (
      <Loader message="loading user profile" />
      //   <div className="max-w-[640px] mx-auto flex flex-col h-screen px-2 md:px-4 py-4">
      //     <section className="flex flex-col gap-4 mt-4">
      //       <div className="size-60 self-center rounded-3xl mt-16 mb-8 bg-[#ffffff1a] animate-pulse" />
      //       <div className="h-12 bg-[#ffffff1a] rounded animate-pulse mx-auto w-3/4" />
      //       <div className="h-12 bg-[#ffffff1a] rounded animate-pulse mx-auto w-2/3" />
      //       <div className="h-6 bg-[#ffffff1a] rounded animate-pulse mx-auto w-1/2 mt-4" />
      //     </section>
      //     <div className="mt-8 self-center px-8 py-2 rounded-full flex gap-3 items-center bg-[#ffffff1a] animate-pulse h-16 w-64" />
      //   </div>
    );

  if (error)
    return (
      <ErrorMessage message={error} />
      //   <div className="max-w-[640px] mx-auto flex flex-col h-screen px-2 md:px-4 py-4 items-center justify-center">
      //     <div className="bg-[#00000080] rounded-xl p-6 max-w-[500px] w-full">
      //       <h2 className="text-xl font-semibold text-white mb-4">Error</h2>
      //       <pre className="text-red-400 whitespace-pre-wrap break-words">
      //         {JSON.stringify(error, null, 2)}
      //       </pre>
      //     </div>
      //   </div>
    );

  return (
    <div className="max-w-[640px] mx-auto flex flex-col h-screen px-2 md:px-4 py-4">
      <section className=" flex flex-col gap-4 mt-4">
        <img
          src="/logo2.png"
          className="size-60 self-center rounded-3xl mt-16 mb-8"
        />
        <p className="text-4xl text-center font-bold">
          you were invited
          <br />
          by @{data?.name}
        </p>
        <p className="font-semibold text-center">
          See yourself and your friends in movies
        </p>
      </section>
      <Footer />
    </div>
  );
}
