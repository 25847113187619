import {configureStore} from "@reduxjs/toolkit";
import MovieSlice from "./slice/Movie";
import Profile from "./slice/Invite";
const store = configureStore({
    reducer : {
        movie : MovieSlice,
        invite : Profile
    }
})

export default store;