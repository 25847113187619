import React, { useEffect, useState } from "react";
import moment from "moment-timezone";

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    const targetTime = moment.tz("2024-12-22 24:00", "America/Los_Angeles");

    const updateCountdown = () => {
      const now = moment();
      const duration = moment.duration(targetTime.diff(now));

      // if (duration.asSeconds() <= 0) {
      //   setTimeLeft("Event has started!");
      // } else {
      //   setTimeLeft(
      //     `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`
      //   );
      // }

      if (duration.asSeconds() <= 0) {
        setTimeLeft({
          finished: true,
        });
        return;
      }

      setTimeLeft({
        days: duration.days(),
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
        finished: false,
      });
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, []);

  if (timeLeft?.finished) {
    return (
      <div className="text-xl">
        <span>Event Started</span>
      </div>
    );
  }

  return (
    <div className="text-xl font-thin flex items-center gap-4">
      {[
        ["days", "Days"],
        ["hours", "hours"],
        ["minutes", "mins"],
        ["seconds", "secs"],
      ].map((item, index) => (
        <div key={index} className="flex w-10 flex-col items-center text-3xl">
          <span className="">{`${timeLeft?.[item[0]]}`.padStart(2, "0")}</span>
          <span className="text-sm">{[item[1]]}</span>
        </div>
      ))}
    </div>
  );
};

export default Countdown;
