import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

export const fetchepisode = createAsyncThunk(
  "fetchTemplate",
  async ({ templateID }) => {
    try {
      const response = await api.post("/videotemplate/get-template-list", {
        limit: 1000,
        lastKey: null,
      });
      const data = response.data.searchData.filter(
        (item) => item.id === templateID
      )[0]
      
      if (!data){
        throw new Error("404, video not found")
      };

      return data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
    movie : null,
    error : "",
    loading : false
}


const movieSlice = createSlice({
    name : "movieSlice",
    initialState,
    extraReducers : (builder) => {
        builder.addCase(fetchepisode.pending, (state, action) => {
            state.movie = null;
            state.error = "";
            state.loading = true;
        });
        builder.addCase(fetchepisode.rejected, (state, action) => {
            state.movie = null;
            state.error = action.error.message;
            state.loading = false;
        });
        builder.addCase(fetchepisode.fulfilled, (state, action) => {
            state.movie = action.payload;
            state.error = "";
            state.loading = false;
        });
    }
})

export default movieSlice.reducer;