import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../utils/api";
import axios from "axios";

export const fetchUser = createAsyncThunk("fetchUser", async ({ id }) => {
  try {
    const response = await api.post("/user/user-details-by-id", {
      userID : id,
    });
    if (!response.data.user) {
      throw new Error("user not found");
    }
    return response.data.user;
  } catch (error) {
    if (axios.isCancel(error)) {
      throw new Error("request was cancelled");
    } else {
      throw new Error(error?.response?.data?.message);
    }
  }
});

const profileSlice = createSlice({
  name: "profileSlice",
  initialState: {
    loading: false,
    error: "",
    data: {
      name: "",
      id: "",
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.data.name =
        action.payload.firstName + " " + action.payload.lastName;
      state.data.id = action.payload.id;
    });
  },
});

export default profileSlice.reducer;